import React, { useState, useRef } from 'react';
import { Form, Input } from 'antd';
import './otpInput.scss'

function Index({ length = 4, onChange }) {
  const [otp, setOTP] = useState(Array(length).fill(''));
  const inputs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) {
      return;
    }
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    const otpText = newOTP.toString().replaceAll(",", "")
    onChange(otpText)
    if (value !== '' && index < length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputs.current[index - 1].focus();
    }
  };

  return (
    <div className='flex'>
      {otp.map((digit, index) => (
        <Form.Item>
          <Input
            key={index}
            type="number"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={(input) => inputs.current.push(input)}
            className='ver_inp'
          />
        </Form.Item>
      ))}
    </div>
  );
}

export default Index;