import React, { useState } from 'react'
import './userSignup.scss'
import { Header, MainPageHeading, NormalInput, NormalBtn } from '../../components'
import nextIcon from '../../assets/images/next.png'
import orangeNext from '../../assets/images/orange_next.png'
import { useNavigate } from 'react-router-dom'


function Index() {

    const navigate = useNavigate();
    const [phone, setPhone] = useState("")

    const changePhone = (e) => setPhone(e.target.value)

    const sendOtp = () => {
        phone?.length > 0 && navigate("/verify-otp")
    }

    return (
        <div className='user__main'>
            <Header type={"back"} />
            <div className='user__contain'>
                <div className='box__shadow'>
                    <div className='sectionOne'>
                        <MainPageHeading text={"Verification"} />
                        <p className='py-4'>Plaese enter your  mobile numberto continuie. we,ll send  verification  code  to this number</p>
                        <div className="flex">
                            <div className="w-3/4">
                                <NormalInput onChange={changePhone} value={phone} placeholder="Your Number" />
                            </div>
                            <div className="w-1/4 flex justify-end">
                                <button onClick={sendOtp}>
                                    <img style={{ height: "44px" }} src={phone?.length > 0 ? orangeNext : nextIcon} />
                                </button>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className=''>
                            <p className='using__text'>
                                Or Continue Using
                            </p>

                            <NormalBtn onlyOutline label="GOOGLE" />
                            <NormalBtn onlyOutline classes="mt-3" label="APPLE ID" />
                            <NormalBtn onlyOutline classes="mt-3" label="GMAIL" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Index