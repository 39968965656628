import React, { useEffect } from "react";
import { offlineIcon } from "./offline";
import Icon from "@ant-design/icons";
import useNetworkState from "../../assets/hooks/netwrok";

export default function InternetConnection(props) {
    const isOnline = useNetworkState();

  useEffect(() => {
    if (!isOnline) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [isOnline]);

  return (
    <React.Fragment>
      <>
        {isOnline === false && (
          <div className="no-connection px-12">
            <div className="text-center flex flex-col items-center justify-center">
              <Icon rev={""} component={offlineIcon} />
              <h3 style={{color: "white"}} className="pt-4 white font-bold text-xl">
                No internet connection
              </h3>
              <p style={{color: "white"}} className="white text-base">
                You seem to be offline. please check your connection 
              </p>
           
            </div>
          </div>
        )}
        {props.children}
      </>
    </React.Fragment>
  );
}
