import colors from "../../theme/colors/colors"

export const LocationIcon = () => {
    return <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00142 8.38783C7.41607 8.38783 7.77056 8.24019 8.0649 7.94492C8.35923 7.64964 8.5064 7.29467 8.5064 6.88002C8.5064 6.46537 8.35876 6.11087 8.06348 5.81654C7.7682 5.52221 7.41324 5.37504 6.99859 5.37504C6.58393 5.37504 6.22944 5.52268 5.93511 5.81796C5.64077 6.11323 5.49361 6.4682 5.49361 6.88285C5.49361 7.29751 5.64125 7.652 5.93652 7.94633C6.2318 8.24067 6.58677 8.38783 7.00142 8.38783ZM7 14.7612C8.63034 13.3019 9.87793 11.902 10.7428 10.5617C11.6076 9.22145 12.0401 8.04757 12.0401 7.04008C12.0401 5.52086 11.5574 4.27193 10.5921 3.29329C9.62687 2.31465 8.42949 1.82533 7 1.82533C5.57052 1.82533 4.37313 2.31465 3.40786 3.29329C2.44258 4.27193 1.95994 5.52086 1.95994 7.04008C1.95994 8.04757 2.39236 9.22145 3.25721 10.5617C4.12207 11.902 5.36967 13.3019 7 14.7612ZM7 16.4247C4.90279 14.6074 3.33015 12.9161 2.28207 11.351C1.234 9.78581 0.709961 8.34885 0.709961 7.04008C0.709961 5.11703 1.33202 3.56015 2.57615 2.36944C3.82026 1.17873 5.29488 0.583374 7 0.583374C8.70513 0.583374 10.1797 1.17873 11.4239 2.36944C12.668 3.56015 13.29 5.11703 13.29 7.04008C13.29 8.34885 12.766 9.78581 11.7179 11.351C10.6699 12.9161 9.09721 14.6074 7 16.4247Z" fill="#666666" />
    </svg>
}

export const RightArrow = () => {
    return <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.56234 8.49999L6.99984 11.0625L8.1665 12.25L11.9165 8.49999L8.1665 4.74999L6.99984 5.93749L9.56234 8.49999ZM8.99984 16.8333C7.84706 16.8333 6.76373 16.6146 5.74984 16.1771C4.73595 15.7396 3.854 15.1458 3.104 14.3958C2.354 13.6458 1.76025 12.7639 1.32275 11.75C0.885254 10.7361 0.666504 9.65277 0.666504 8.49999C0.666504 7.34721 0.885254 6.26388 1.32275 5.24999C1.76025 4.2361 2.354 3.35416 3.104 2.60416C3.854 1.85416 4.73595 1.26041 5.74984 0.822906C6.76373 0.385406 7.84706 0.166656 8.99984 0.166656C10.1526 0.166656 11.2359 0.385406 12.2498 0.822906C13.2637 1.26041 14.1457 1.85416 14.8957 2.60416C15.6457 3.35416 16.2394 4.2361 16.6769 5.24999C17.1144 6.26388 17.3332 7.34721 17.3332 8.49999C17.3332 9.65277 17.1144 10.7361 16.6769 11.75C16.2394 12.7639 15.6457 13.6458 14.8957 14.3958C14.1457 15.1458 13.2637 15.7396 12.2498 16.1771C11.2359 16.6146 10.1526 16.8333 8.99984 16.8333ZM8.99984 15.1667C10.8609 15.1667 12.4373 14.5208 13.729 13.2292C15.0207 11.9375 15.6665 10.3611 15.6665 8.49999C15.6665 6.63888 15.0207 5.06249 13.729 3.77082C12.4373 2.47916 10.8609 1.83332 8.99984 1.83332C7.13873 1.83332 5.56234 2.47916 4.27067 3.77082C2.979 5.06249 2.33317 6.63888 2.33317 8.49999C2.33317 10.3611 2.979 11.9375 4.27067 13.2292C5.56234 14.5208 7.13873 15.1667 8.99984 15.1667Z" fill="white" />
    </svg>
}

export const MenuIcon = () => {
    return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="21.3" fill={colors.secondary} stroke={colors.primary} stroke-width="1.4" />
        <path d="M13.5 27.6346V26.1346H30.5V27.6346H13.5ZM13.5 22.75V21.25H30.5V22.75H13.5ZM13.5 17.8654V16.3654H30.5V17.8654H13.5Z" fill={colors.primary} />
    </svg>
}

export const SearchIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5422 20.5769L13.2615 14.2961C12.7615 14.7089 12.1865 15.032 11.5365 15.2653C10.8865 15.4987 10.214 15.6153 9.51916 15.6153C7.80999 15.6153 6.36348 15.0236 5.17961 13.84C3.99574 12.6564 3.40381 11.2103 3.40381 9.50157C3.40381 7.79284 3.99559 6.34616 5.17916 5.16154C6.36273 3.97694 7.80888 3.38464 9.51761 3.38464C11.2263 3.38464 12.673 3.97658 13.8576 5.16044C15.0422 6.34431 15.6345 7.79083 15.6345 9.49999C15.6345 10.2141 15.5147 10.8961 15.2749 11.5461C15.0352 12.1961 14.7153 12.7615 14.3153 13.2423L20.5961 19.5231L19.5422 20.5769ZM9.51916 14.1154C10.8076 14.1154 11.899 13.6683 12.7932 12.774C13.6874 11.8798 14.1346 10.7885 14.1346 9.49999C14.1346 8.21153 13.6874 7.12018 12.7932 6.22594C11.899 5.33171 10.8076 4.88459 9.51916 4.88459C8.23069 4.88459 7.13934 5.33171 6.24511 6.22594C5.35089 7.12018 4.90378 8.21153 4.90378 9.49999C4.90378 10.7885 5.35089 11.8798 6.24511 12.774C7.13934 13.6683 8.23069 14.1154 9.51916 14.1154Z" fill="#ADADAD" />
    </svg>
}

export const BackIcon = () => {
    return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="21.3" fill={colors.secondary} stroke={colors.primary} stroke-width="1.4" />
        <path d="M17.373 22.75L23.0692 28.4461L22 29.5L14.5 22L22 14.5L23.0692 15.5538L17.373 21.25H29.5V22.75H17.373Z" fill={colors.primary} />
    </svg>
}

export const FilterIcon = () => {
    return <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.27885 11.5V9.99998H10.7115V11.5H7.27885ZM3.40385 6.74995V5.25H14.5865V6.74995H3.40385ZM0.5 1.99998V0.5H17.5V1.99998H0.5Z" fill={colors.primary} />
    </svg>
}

export const TagCloseIcon = () => {
    return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.60007 10.1026L7.00007 7.70256L9.40007 10.1026L10.1026 9.40001L7.70262 7.00001L10.1026 4.6L9.40007 3.89745L7.00007 6.29745L4.60007 3.89745L3.89751 4.6L6.29752 7.00001L3.89751 9.40001L4.60007 10.1026ZM7.00118 13.3333C6.12523 13.3333 5.30187 13.1671 4.53112 12.8347C3.76035 12.5022 3.08989 12.051 2.51975 11.4812C1.94959 10.9113 1.49822 10.2411 1.16563 9.47067C0.833043 8.70026 0.666748 7.87708 0.666748 7.00112C0.666748 6.12517 0.83297 5.30181 1.16541 4.53105C1.49786 3.76029 1.94903 3.08983 2.51891 2.51969C3.08881 1.94953 3.75898 1.49816 4.5294 1.16557C5.29981 0.832982 6.12299 0.666687 6.99895 0.666687C7.87491 0.666687 8.69826 0.83291 9.46902 1.16535C10.2398 1.4978 10.9102 1.94897 11.4804 2.51885C12.0505 3.08875 12.5019 3.75892 12.8345 4.52934C13.1671 5.29975 13.3334 6.12293 13.3334 6.99889C13.3334 7.87484 13.1672 8.6982 12.8347 9.46896C12.5023 10.2397 12.0511 10.9102 11.4812 11.4803C10.9113 12.0505 10.2412 12.5019 9.47073 12.8344C8.70032 13.167 7.87714 13.3333 7.00118 13.3333ZM7.00007 12.3333C8.48896 12.3333 9.75007 11.8167 10.7834 10.7833C11.8167 9.75001 12.3334 8.48889 12.3334 7.00001C12.3334 5.51112 11.8167 4.25 10.7834 3.21667C9.75007 2.18334 8.48896 1.66667 7.00007 1.66667C5.51118 1.66667 4.25007 2.18334 3.21673 3.21667C2.1834 4.25 1.66673 5.51112 1.66673 7.00001C1.66673 8.48889 2.1834 9.75001 3.21673 10.7833C4.25007 11.8167 5.51118 12.3333 7.00007 12.3333Z" fill="white" />
    </svg>
}

export const DetailsIcon = () => {
    return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="21.3" fill={colors.secondary} stroke={colors.primary} stroke-width="1.4" />
        <path d="M25 30.4615L19 28.3615L14.6963 30.0268C14.4014 30.1409 14.1283 30.1083 13.877 29.9288C13.6257 29.7493 13.5 29.5038 13.5 29.1923V16.077C13.5 15.8795 13.5529 15.7023 13.6588 15.5453C13.7644 15.3883 13.9128 15.2745 14.104 15.204L19 13.5385L25 15.6385L29.3038 13.9733C29.5986 13.8591 29.8717 13.8869 30.123 14.0568C30.3743 14.2266 30.5 14.4641 30.5 14.7693V27.9615C30.5 28.1653 30.4423 28.3442 30.327 28.498C30.2115 28.6518 30.0551 28.764 29.8577 28.8345L25 30.4615ZM24.25 28.627V16.927L19.75 15.3538V27.0538L24.25 28.627ZM25.75 28.627L29 27.55V15.7L25.75 16.927V28.627ZM15 28.3L18.25 27.0538V15.3538L15 16.45V28.3Z" fill={colors.primary} />
    </svg>
}

export const CallIcon = () => {
    return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="21.3" fill={colors.secondary} stroke={colors.primary} stroke-width="1.4" />
        <path d="M29.4402 30.5C27.5557 30.5 25.6625 30.0618 23.7605 29.1855C21.8587 28.3092 20.1113 27.073 18.5183 25.477C16.9254 23.8808 15.6908 22.1333 14.8145 20.2345C13.9382 18.3358 13.5 16.4443 13.5 14.5598C13.5 14.2569 13.6 14.0046 13.8 13.8027C14 13.6009 14.25 13.5 14.55 13.5H17.8115C18.064 13.5 18.2868 13.5824 18.4798 13.7473C18.6727 13.9119 18.7955 14.1154 18.848 14.3577L19.4213 17.3C19.4609 17.573 19.4526 17.8076 19.3963 18.0038C19.3398 18.1999 19.2384 18.3647 19.0923 18.498L16.7827 20.7462C17.1544 21.4269 17.5791 22.0708 18.0567 22.678C18.5342 23.285 19.0512 23.8647 19.6077 24.4173C20.1564 24.9661 20.7397 25.4757 21.3577 25.9462C21.9757 26.4167 22.6431 26.8546 23.3598 27.2598L25.6038 24.9963C25.7603 24.8334 25.9498 24.7192 26.1723 24.6538C26.3946 24.5884 26.6257 24.5724 26.8655 24.6058L29.6423 25.1713C29.8948 25.2379 30.1008 25.3667 30.2605 25.5577C30.4202 25.7487 30.5 25.9654 30.5 26.2078V29.45C30.5 29.75 30.3991 30 30.1973 30.2C29.9954 30.4 29.7431 30.5 29.4402 30.5ZM16.073 19.327L17.8577 17.6193C17.8898 17.5936 17.9106 17.5583 17.9202 17.5135C17.9299 17.4687 17.9283 17.427 17.9155 17.3885L17.4808 15.1538C17.4679 15.1026 17.4455 15.0642 17.4135 15.0385C17.3815 15.0128 17.3398 15 17.2885 15H15.15C15.1115 15 15.0794 15.0128 15.0537 15.0385C15.0282 15.0642 15.0155 15.0963 15.0155 15.1348C15.0667 15.8181 15.1785 16.5123 15.351 17.2173C15.5233 17.9224 15.764 18.6257 16.073 19.327ZM24.773 27.9693C25.4358 28.2783 26.1272 28.5145 26.847 28.678C27.567 28.8413 28.2397 28.9384 28.8652 28.9693C28.9037 28.9693 28.9358 28.9564 28.9615 28.9307C28.9872 28.9051 29 28.873 29 28.8345V26.7308C29 26.6794 28.9872 26.6377 28.9615 26.6058C28.9358 26.5737 28.8974 26.5513 28.8462 26.5385L26.7462 26.1115C26.7077 26.0987 26.6741 26.0971 26.6453 26.1067C26.6164 26.1164 26.5859 26.1372 26.5538 26.1692L24.773 27.9693Z" fill={colors.primary} />
    </svg>
}
export const DwonArrowIcon = () => {
    return <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.0001 6.03201L0.288574 1.32051L1.16676 0.442322L5.0001 4.27566L8.83343 0.442322L9.71162 1.32051L5.0001 6.03201Z" fill={colors.primary} />
    </svg>



}
export const NextArrowIcon = () => {
    return <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.53207 5.00003L1.82057 9.71155L0.942383 8.83336L4.77572 5.00003L0.942383 1.16669L1.82057 0.288506L6.53207 5.00003Z" fill={colors.primary} />
    </svg>
}
export const FitnessIcon = () => {
    return <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.49998 10.7884V12.6923C1.49998 12.7692 1.53202 12.8397 1.59613 12.9038C1.66024 12.9679 1.73077 13 1.8077 13H17.1923C17.2692 13 17.3397 12.9679 17.4038 12.9038C17.4679 12.8397 17.5 12.7692 17.5 12.6923V10.7884H1.49998ZM1.8077 0.5H17.1923C17.691 0.5 18.1169 0.676608 18.4701 1.02982C18.8233 1.38302 19 1.80898 19 2.3077V12.6923C19 13.191 18.8233 13.6169 18.4701 13.9701C18.1169 14.3233 17.691 14.5 17.1923 14.5H12.9615V19.1345L9.49998 17.4038L6.03848 19.1345V14.5H1.8077C1.30898 14.5 0.883025 14.3233 0.529825 13.9701C0.176608 13.6169 0 13.191 0 12.6923V2.3077C0 1.80898 0.176608 1.38302 0.529825 1.02982C0.883025 0.676608 1.30898 0.5 1.8077 0.5ZM1.49998 8.21153H17.5V2.3077C17.5 2.23077 17.4679 2.16024 17.4038 2.09613C17.3397 2.03203 17.2692 1.99998 17.1923 1.99998H1.8077C1.73077 1.99998 1.66024 2.03203 1.59613 2.09613C1.53202 2.16024 1.49998 2.23077 1.49998 2.3077V8.21153ZM1.49998 12.6923V1.99998H1.8077C1.73077 1.99998 1.66024 2.03203 1.59613 2.09613C1.53202 2.16024 1.49998 2.23077 1.49998 2.3077V12.6923C1.49998 12.7692 1.53202 12.8397 1.59613 12.9038C1.66024 12.9679 1.73077 13 1.8077 13H1.49998V12.6923Z" fill={colors.primary} />
    </svg>
}


export const NextIcon = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.85914 7.9999L6.29664 10.5624L7.16681 11.4534L10.6203 7.9999L7.16681 4.5464L6.29664 5.4374L8.85914 7.9999ZM8.00154 15.9165C6.90659 15.9165 5.8774 15.7088 4.91396 15.2932C3.9505 14.8777 3.11243 14.3137 2.39975 13.6013C1.68705 12.889 1.12284 12.0513 0.7071 11.0882C0.291364 10.1252 0.0834961 9.09624 0.0834961 8.00129C0.0834961 6.90635 0.291274 5.87716 0.70683 4.91371C1.12239 3.95025 1.68634 3.11218 2.3987 2.3995C3.11108 1.68681 3.94878 1.12259 4.91181 0.706857C5.87482 0.291121 6.9038 0.083252 7.99875 0.083252C9.09369 0.083252 10.1229 0.291031 11.0863 0.706586C12.0498 1.12214 12.8879 1.6861 13.6005 2.39846C14.3132 3.11084 14.8774 3.94854 15.2932 4.91157C15.7089 5.87458 15.9168 6.90356 15.9168 7.9985C15.9168 9.09345 15.709 10.1226 15.2935 11.0861C14.8779 12.0495 14.3139 12.8876 13.6016 13.6003C12.8892 14.313 12.0515 14.8772 11.0885 15.2929C10.1255 15.7087 9.09648 15.9165 8.00154 15.9165ZM8.00014 14.6666C9.86125 14.6666 11.4376 14.0207 12.7293 12.7291C14.021 11.4374 14.6668 9.86101 14.6668 7.9999C14.6668 6.13879 14.021 4.5624 12.7293 3.27073C11.4376 1.97907 9.86125 1.33323 8.00014 1.33323C6.13903 1.33323 4.56264 1.97907 3.27098 3.27073C1.97931 4.5624 1.33348 6.13879 1.33348 7.9999C1.33348 9.86101 1.97931 11.4374 3.27098 12.7291C4.56264 14.0207 6.13903 14.6666 8.00014 14.6666Z" fill="white" />
    </svg>
}





export const CLoseIcon = () => {
    return <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.39998 14.1538L9.99998 10.5538L13.6 14.1538L14.6538 13.1L11.0538 9.5L14.6538 5.9L13.6 4.84617L9.99998 8.44617L6.39998 4.84617L5.34615 5.9L8.94615 9.5L5.34615 13.1L6.39998 14.1538ZM10.0016 19C8.68772 19 7.45268 18.7506 6.29655 18.252C5.1404 17.7533 4.13472 17.0766 3.2795 16.2217C2.42427 15.3669 1.74721 14.3616 1.24833 13.206C0.749442 12.0504 0.5 10.8156 0.5 9.50167C0.5 8.18774 0.749334 6.95271 1.248 5.79657C1.74667 4.64042 2.42342 3.63474 3.27825 2.77952C4.1331 1.92429 5.13834 1.24723 6.29398 0.748349C7.44959 0.249466 8.68437 2.28882e-05 9.9983 2.28882e-05C11.3122 2.28882e-05 12.5473 0.249357 13.7034 0.748024C14.8596 1.24669 15.8652 1.92344 16.7205 2.77827C17.5757 3.63312 18.2527 4.63837 18.7516 5.794C19.2505 6.94962 19.5 8.18439 19.5 9.49832C19.5 10.8123 19.2506 12.0473 18.752 13.2034C18.2533 14.3596 17.5765 15.3653 16.7217 16.2205C15.8669 17.0757 14.8616 17.7528 13.706 18.2516C12.5504 18.7505 11.3156 19 10.0016 19ZM9.99998 17.5C12.2333 17.5 14.125 16.725 15.675 15.175C17.225 13.625 18 11.7333 18 9.5C18 7.26667 17.225 5.375 15.675 3.825C14.125 2.275 12.2333 1.5 9.99998 1.5C7.76664 1.5 5.87498 2.275 4.32498 3.825C2.77498 5.375 1.99998 7.26667 1.99998 9.5C1.99998 11.7333 2.77498 13.625 4.32498 15.175C5.87498 16.725 7.76664 17.5 9.99998 17.5Z" fill="#ADADAD" />
    </svg>
}

export const CloseOutlinedIcon = () => {
    return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.40002 13.6538L0.346191 12.6L5.94619 7.00002L0.346191 1.40002L1.40002 0.346191L7.00002 5.94619L12.6 0.346191L13.6538 1.40002L8.05384 7.00002L13.6538 12.6L12.6 13.6538L7.00002 8.05384L1.40002 13.6538Z" fill="#ADADAD"/>
    </svg>
    
}

export const CLoseIconcolour = () => {
    return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.00014 12.3782L8.00014 9.37819L11.0001 12.3782L11.8783 11.5L8.87833 8.5L11.8783 5.5L11.0001 4.62181L8.00014 7.62181L5.00014 4.62181L4.12195 5.5L7.12195 8.5L4.12195 11.5L5.00014 12.3782ZM8.00154 16.4166C6.90659 16.4166 5.8774 16.2089 4.91396 15.7933C3.9505 15.3778 3.11243 14.8138 2.39975 14.1014C1.68705 13.3891 1.12284 12.5514 0.7071 11.5883C0.291364 10.6253 0.0834961 9.59634 0.0834961 8.5014C0.0834961 7.40645 0.291274 6.37726 0.70683 5.41381C1.12239 4.45035 1.68634 3.61229 2.3987 2.8996C3.11108 2.18691 3.94878 1.62269 4.91181 1.20696C5.87482 0.791222 6.9038 0.583353 7.99875 0.583353C9.09369 0.583353 10.1229 0.791132 11.0863 1.20669C12.0498 1.62224 12.8879 2.1862 13.6005 2.89856C14.3132 3.61094 14.8774 4.44864 15.2932 5.41167C15.7089 6.37468 15.9168 7.40366 15.9168 8.4986C15.9168 9.59355 15.709 10.6227 15.2935 11.5862C14.8779 12.5496 14.3139 13.3877 13.6016 14.1004C12.8892 14.8131 12.0515 15.3773 11.0885 15.793C10.1255 16.2088 9.09648 16.4166 8.00154 16.4166ZM8.00014 15.1667C9.86125 15.1667 11.4376 14.5208 12.7293 13.2292C14.021 11.9375 14.6668 10.3611 14.6668 8.5C14.6668 6.63889 14.021 5.0625 12.7293 3.77083C11.4376 2.47917 9.86125 1.83333 8.00014 1.83333C6.13903 1.83333 4.56264 2.47917 3.27098 3.77083C1.97931 5.0625 1.33348 6.63889 1.33348 8.5C1.33348 10.3611 1.97931 11.9375 3.27098 13.2292C4.56264 14.5208 6.13903 15.1667 8.00014 15.1667Z" fill={colors.primary}/>
    </svg>

}

export const Ipassicon = () => {
    return<svg width="46" height="12" viewBox="0 0 46 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.5654 1.45939L44.4075 2.97493C43.7434 2.08944 42.9601 2.0043 42.5514 2.0043C41.3594 2.0043 40.9848 2.75356 40.9848 3.33252C40.9848 3.60498 41.0699 3.86041 41.3423 4.09881C41.6148 4.35423 41.9894 4.49046 42.7046 4.74589C43.5901 5.0524 44.4245 5.35892 45.0035 5.8868C45.5143 6.34657 45.9741 7.11285 45.9741 8.27079C45.9741 10.4845 44.3734 12 42.0235 12C39.929 12 38.7029 10.7229 38.1069 9.85444L39.5544 8.47513C40.0993 9.58199 41.138 9.99067 41.9383 9.99067C42.943 9.99067 43.6923 9.37764 43.6923 8.42405C43.6923 8.01536 43.5561 7.69182 43.2495 7.40234C42.8238 7.01068 42.1427 6.78931 41.4956 6.56794C40.8996 6.3636 40.1844 6.09114 39.6054 5.58029C39.2308 5.25675 38.7029 4.62669 38.7029 3.46875C38.7029 1.79996 39.8268 0.131165 42.347 0.131165C43.0622 0.131165 44.4075 0.267393 45.5654 1.45939Z" fill="white"/>
    <path d="M35.5854 1.45939L34.4275 2.97493C33.7634 2.08944 32.9801 2.0043 32.5714 2.0043C31.3794 2.0043 31.0048 2.75356 31.0048 3.33252C31.0048 3.60498 31.0899 3.86041 31.3624 4.09881C31.6348 4.35423 32.0095 4.49046 32.7246 4.74589C33.6101 5.0524 34.4445 5.35892 35.0235 5.8868C35.5344 6.34657 35.9941 7.11285 35.9941 8.27079C35.9941 10.4845 34.3934 12 32.0435 12C29.949 12 28.723 10.7229 28.127 9.85444L29.5744 8.47513C30.1193 9.58199 31.158 9.99067 31.9584 9.99067C32.963 9.99067 33.7123 9.37764 33.7123 8.42405C33.7123 8.01536 33.5761 7.69182 33.2696 7.40234C32.8438 7.01068 32.1627 6.78931 31.5156 6.56794C30.9196 6.3636 30.2044 6.09114 29.6255 5.58029C29.2508 5.25675 28.7229 4.62669 28.7229 3.46875C28.7229 1.79996 29.8468 0.131165 32.3671 0.131165C33.0822 0.131165 34.4275 0.267393 35.5854 1.45939Z" fill="white"/>
    <path d="M24.2818 11.7446L20.9868 4.99645L17.6918 11.7446H15.2908L20.9868 0.386597L26.6658 11.7446H24.2818Z" fill="white"/>
    <path d="M7.25269 0.386597H10.5562C11.8334 0.386597 12.7188 0.522825 13.5532 1.25505C14.4728 2.07242 14.7112 3.17927 14.7112 3.99664C14.7112 4.43938 14.6431 5.66543 13.7746 6.53389C13.1445 7.16394 12.208 7.53857 10.8968 7.53857H9.46639V11.7446H7.25269V0.386597ZM9.46639 2.29379V5.63138H10.3689C10.7946 5.63138 11.3395 5.63138 11.8163 5.27378C12.225 4.95024 12.4294 4.43938 12.4294 3.94555C12.4294 3.3155 12.1228 2.88979 11.7993 2.65139C11.3395 2.32785 10.7606 2.29379 10.2327 2.29379H9.46639Z" fill="white"/>
    <path d="M0.684448 4.7937H2.76193V11.7448H0.684448V4.7937Z" fill="white"/>
    <path d="M0 1.75287L1.7529 -3.35829e-05L3.46338 1.71045L1.71048 3.46335L0 1.75287Z" fill="white"/>
    </svg>
    }

export const PassDetailIcon = () => {
    return<svg width="46" height="12" viewBox="0 0 46 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.5654 1.45939L44.4075 2.97493C43.7434 2.08944 42.9601 2.0043 42.5514 2.0043C41.3594 2.0043 40.9848 2.75356 40.9848 3.33252C40.9848 3.60498 41.0699 3.86041 41.3423 4.09881C41.6148 4.35423 41.9894 4.49046 42.7046 4.74589C43.5901 5.0524 44.4245 5.35892 45.0035 5.8868C45.5143 6.34657 45.9741 7.11285 45.9741 8.27079C45.9741 10.4845 44.3734 12 42.0235 12C39.929 12 38.7029 10.7229 38.1069 9.85444L39.5544 8.47513C40.0993 9.58199 41.138 9.99067 41.9383 9.99067C42.943 9.99067 43.6923 9.37764 43.6923 8.42405C43.6923 8.01536 43.5561 7.69182 43.2495 7.40234C42.8238 7.01068 42.1427 6.78931 41.4956 6.56794C40.8996 6.3636 40.1844 6.09114 39.6054 5.58029C39.2308 5.25675 38.7029 4.62669 38.7029 3.46875C38.7029 1.79996 39.8268 0.131165 42.347 0.131165C43.0622 0.131165 44.4075 0.267393 45.5654 1.45939Z" fill="white"/>
    <path d="M35.5854 1.45939L34.4275 2.97493C33.7634 2.08944 32.9801 2.0043 32.5714 2.0043C31.3794 2.0043 31.0048 2.75356 31.0048 3.33252C31.0048 3.60498 31.0899 3.86041 31.3624 4.09881C31.6348 4.35423 32.0095 4.49046 32.7246 4.74589C33.6101 5.0524 34.4445 5.35892 35.0235 5.8868C35.5344 6.34657 35.9941 7.11285 35.9941 8.27079C35.9941 10.4845 34.3934 12 32.0435 12C29.949 12 28.723 10.7229 28.127 9.85444L29.5744 8.47513C30.1193 9.58199 31.158 9.99067 31.9584 9.99067C32.963 9.99067 33.7123 9.37764 33.7123 8.42405C33.7123 8.01536 33.5761 7.69182 33.2696 7.40234C32.8438 7.01068 32.1627 6.78931 31.5156 6.56794C30.9196 6.3636 30.2044 6.09114 29.6255 5.58029C29.2508 5.25675 28.7229 4.62669 28.7229 3.46875C28.7229 1.79996 29.8468 0.131165 32.3671 0.131165C33.0822 0.131165 34.4275 0.267393 35.5854 1.45939Z" fill="white"/>
    <path d="M24.2818 11.7446L20.9868 4.99645L17.6918 11.7446H15.2908L20.9868 0.386597L26.6658 11.7446H24.2818Z" fill="white"/>
    <path d="M7.25269 0.386597H10.5562C11.8334 0.386597 12.7188 0.522825 13.5532 1.25505C14.4728 2.07242 14.7112 3.17927 14.7112 3.99664C14.7112 4.43938 14.6431 5.66543 13.7746 6.53389C13.1445 7.16394 12.208 7.53857 10.8968 7.53857H9.46639V11.7446H7.25269V0.386597ZM9.46639 2.29379V5.63138H10.3689C10.7946 5.63138 11.3395 5.63138 11.8163 5.27378C12.225 4.95024 12.4294 4.43938 12.4294 3.94555C12.4294 3.3155 12.1228 2.88979 11.7993 2.65139C11.3395 2.32785 10.7606 2.29379 10.2327 2.29379H9.46639Z" fill="white"/>
    <path d="M0.684448 4.7937H2.76193V11.7448H0.684448V4.7937Z" fill="white"/>
    <path d="M0 1.75287L1.7529 -3.35829e-05L3.46338 1.71045L1.71048 3.46335L0 1.75287Z" fill="white"/>
    </svg>
}





