import './App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { ConfigProvider } from 'antd';
import AppRouter from './router/router';
import AuthProvider from './context';
import { RootContainer } from './layouts';
import { Encoding } from './functions/base64';
import InternetConnection from './components/noConnection/noConnection';
import LanguageProvider, { LanguageContext } from './components/context/languageContext';
import { getAccessToken } from './functions/common';

function App() {
  const [authToken, setAuthToken] = useState(null);
  useEffect(() => {
    const preVToken = getAccessToken()
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('VPUAuthorization');
    const passId = urlParams.get('pass_id');
    if (token) {
      setAuthToken(token);
      const authString = token?.split('_MR__');
      const access_token = authString[0];
      if(preVToken != access_token){
        localStorage.clear()
      }
      const refresh_token = authString[1];
      localStorage.setItem('access_token', Encoding(access_token));
      localStorage.setItem('refresh_token', Encoding(refresh_token));
    }
    if (passId) {
      window.location.href = `/pass-details?passId=${passId}`;
    }

  }, []);

  return (
    <LanguageProvider> {/* Initialize LanguageProvider first */}
      <AppContent /> {/* Render content that uses the context */}
    </LanguageProvider>
  );
}

function AppContent() {
  // Now you can access LanguageContext here after LanguageProvider is initialized
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (language === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');  // Set RTL for Arabic
    } else {
      document.documentElement.setAttribute('dir', 'ltr');  // Set LTR for other languages
    }
  }, [language]);  // Trigger the effect whenever `language` changes


  return (
    <ConfigProvider direction={language === 'en' ? 'ltr' : 'rtl'}>
      <InternetConnection>
        <AuthProvider>
          <RootContainer>
            <AppRouter />
          </RootContainer>
        </AuthProvider>
      </InternetConnection>
    </ConfigProvider>
  );
}

export default App;
