// 
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PassList, Splash } from '../../layouts';
import Onboarding from '../onbording';
import './home.scss'
import { Header, NormalBtn } from '../../components';
import { getAccessToken } from '../../functions/common';



const Index = () => {

  const navigate = useNavigate();
  const [splash, setSplash] = useState(true)
  const [signedIn, setSignedIn] = useState(false)
  const [onboardingSteps, setOnBoardingSteps] = useState(2)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
   const token = urlParams.get('VPUAuthorization');
   const accessToken = getAccessToken()
    if(token | accessToken){
      setSplash(false);
      setOnBoardingSteps(2);
      setSignedIn(true);
    }
    setTimeout(() => {
      // navigate('/onbording', { replace: true })
      setSplash(false)
    }, 3000);
  }, []);

  const getOnBoardingScreenCount = (val) => {
    setOnBoardingSteps(val)
  }
  return (
    <div className='w-100 overflow-x-hidden'>
      {splash ? <div className={`splash__main ${splash ? 'visible' : 'hidden'}`}>
        <Splash />
      </div> :
        onboardingSteps !== 2 ? (
          <div className='onboarding__container'>
            <Splash classes={"w-1/2"} />
            <Onboarding getCount={(val) => getOnBoardingScreenCount(val)} />
          </div>
        ) : null
      }
      {!splash && <div className='fade-in content-center'>
        <Header withLogo type={"menu"} />
        {!signedIn ? <div className='welcome__div'>
          <h2>
            Welcome to iPass!
          </h2>
          <p>
            Explore exclusive passes tailored for you. Sign up now to unlock exciting benefits.
          </p>
          <NormalBtn
            classes={"mt-6"}
            label={"SIGN UP"}
            onClick={() => navigate('/signup')}
          />
        </div> :
    ""
        //  <div className='welcome__div signin__user'>
        //     <h2>
        //       Mohammed Ahmed
        //     </h2>
        //     <p>
        //       Purchase a pass of your choice to enjoy exclusive benefits
        //     </p>
        //   </div> 
          }
        
      
        <PassList />
      </div>}

      {/* <Container>
        <Navbar />
        <p className="text-3xl font-bold underline">
          <Input placeholder="Basic usage" />
          <Button type="primary">Primary Button</Button>
          <Button>Default Button</Button>
          <Button type="dashed">Dashed Button</Button>
        </p>
      </Container> */}
    </div>
  );
}

export default Index;
