import React from 'react'
import './roundedButton.scss'

function Index(props) {
  return (
    <div>
      <button className='button_style1 ' onClick={props.onClick}>
        {props.label}
      </button>
    </div>
  )
}

export default Index