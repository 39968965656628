import React, { createContext, useState, useEffect } from 'react';

// Create a Context
export const LanguageContext = createContext();

// Create a Provider Component
const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // Default language

  useEffect(() => {
    // Check if a language is saved in localStorage when the app starts
    const savedLanguage = localStorage.getItem('appLanguage');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  // Function to toggle the language
  const toggleLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('appLanguage', lang); // Optional: store language in localStorage
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
