import React from "react";
import { RightArrow } from "../../../assets/icons/Icons";
import './style.scss'

const Index = (props) => {
    const {data, onClick} = props;
    return <button className="pass__card__main" onClick={onClick}>
        <img src={data?.passes[0]?.medias?.[0]?.path} />
        <div className="text__section">
            <h2>
                {data?.name}
            </h2>
            <p>
                {data?.description}
            </p>
            <button className="flex text-white py-2 gap-2 items-center">
                <div>
                    Explore Benefits
                </div>
                <div>
                    <RightArrow />
                </div>
            </button>
        </div>
    </button>
}

export default Index;