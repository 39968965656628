import React, {useState} from 'react';
import './fitness.scss';
import { Header, Bottomlogo, Ipasscard } from '../../components';
import { CategoryList } from '../../layouts';
import { userIsLoggedIn } from '../../functions/common';
import useSelectedLng from '../../functions/seletedLng';

function Index() {
    const currentLng = useSelectedLng()
    const [passName, setPassName] = useState(null);


    return (
        <div className='category__main content-center'>
            {/* <Header placeholder="Search this Pass" type={"back"} /> */}
            <div className='category__title'>
              {!userIsLoggedIn() ? <>  <h2>
                   {passName?.name}
                </h2>
                <p className='mt-2'>
                    Explore various categories tailored to your fitness needs. From strength training to yoga, find your perfect workout routine here.
                </p>
                </> :
                <>
                <Ipasscard />
                <h2 className='mt-8'>
                   {passName?.name}
                </h2>
                </>
                }
            </div>
            <CategoryList setPassName={setPassName} />
         {userIsLoggedIn() && <div className='postion_box'>
                <button className={currentLng === "en" ? 'bottom_logo' : 'bottom_logo_arb'}>
                    <Bottomlogo />
                </button>
            </div>}
        </div>
    );
}

export default Index;
