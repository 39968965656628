import React from 'react'
import './passcard.scss'
import { Header, Ipasscard, Passigcard } from '../../components'
import config from '../../config'
import useSelectedLng from '../../functions/seletedLng'
import translatedText from '../../globalization/language'

export default function PassCard() {
  const currentLng = useSelectedLng()
    return (
        <div className='flex_setting content-center'>
        <div>
          <Header type="back" />
        </div>
        <div className='passCardWrapper'>
          <Ipasscard mainCard={true} />
        </div>
        <div className='passCardText'>
          <p className='cardText'>
            {translatedText(currentLng).present_id}
          </p>
        </div>

      <div className="version-container">
        <p className='version-text'>Web Version: {config.appVersion}</p>
      </div>
    </div>
  )
}
