import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './slider.scss';
import { Pagination } from 'swiper/modules';
import { NormalBtn } from '../..';
import onborderBg1 from './../../../assets/images/onboarding/onboarding1.png'
import onborderBg2 from './../../../assets/images/onboarding/onboarding2.png'
import onborderBg3 from './../../../assets/images/onboarding/onboarding3.png'

export default function Index(props) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const swiperRef = useRef(null);

  const sliderData = [
    {
      title: "Unlock Exclusive Privileges!",
      description: "Drive into a world of perks and privileges tailored just for you. Sign up now to access discounts, VIP packages, and more!",
      bgImg: onborderBg1
    },
    {
      title: "Experience Luxury Like Never Before",
      description: "Elevate your lifestyle with our exclusive Black Pass and Black Pass. Sign up today for early access to fine dining, luxury retail, and premium cinema discounts",
      bgImg: onborderBg2
    },
    {
      title: "Discover Your Personalized Pass!",
      description: "Find the perfect pass for your interests, whether it's fitness, wellness adventure, or more. Sign up now to unlock a range of special offers and unique perks!",
      bgImg: onborderBg3
    }
  ];

  
  const handleClick = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
      setCurrentSlideIndex((prevIndex) => prevIndex + 1);
    }
    // props.getCount(currentSlideIndex)
  };

  return (
    <>
      <Swiper ref={swiperRef} pagination={true} modules={[Pagination]} className="mySwiper p-0" style={{}} speed={1200} effect="fade">
        {sliderData?.map((v, i) => {
          return (
            <SwiperSlide key={i} onClick={handleClick} className="slide">
              <div className='slide__main' style={{ backgroundImage: `url(${v.bgImg})` }}>
                <div className='slider__main px-12 py-10'>
                  <h1 className='text-white pt-10 pb-8'>{v?.title}</h1>
                  <p className='text-white'>{v?.description}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className=''>
        <div className='exploreWrapper'>
          <div className='flex items-center h-full'>
            <NormalBtn onClick={() => props.getCount(2)} label="EXPLORE" classes="w-2/3 mx-6" />
          </div>
        </div>
      </div>
    </>
  );
}
