import { Button } from "antd";
import sadFace from '../../assets/images/sad-face-icon.svg'

export default function Relogin(props) {
  return (
<div className="under-maintenance px-12 h-screen flex items-center justify-center">
  <div className="text-center flex flex-col items-center justify-center test">
  <img src={sadFace} style={{width:"60px"}} />
    <p className="black text-base mt-8">
        Sorry, it looks like you've been inactive for too long Please close this screen and access it again.
    </p>
  </div>
</div>
  );
}
