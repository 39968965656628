import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Header, MainPageHeading, Verifyinput } from '../../components';
import './verifyotp.scss'


function Index() {

    const navigate = useNavigate();

    const [otp, setOtp] = useState("")
    const onChangeOtp = (numb) => {
        setOtp(numb)
        if (numb?.length === 4) {
            navigate("/edit-profile")
        }
    }

    return (
        <div className='user__main'>
            <Header type={"back"} />
            <div className='user__contain'>
                <div className='box__shadow'>
                    <MainPageHeading text={"Verification"} />
                    <p>We've sent a code to your mobile number. Please enter the 4-digit code to verify your account.</p>
                    <div className='inp__display mt-6'>
                        <Verifyinput onChange={onChangeOtp} value={otp} />
                    </div>
                </div>
                <div>
                    <p className='resend__text'>Resend code  in 4:59</p>
                </div>
            </div>
        </div>
    )
}

export default Index;