import { getAccessToken } from "../../../../functions/common";
import ApiRoutes from "../../../apiRoutes/routes"
import { Get } from "../../request"


export const fetchAllPasses = async (navigate) => {
    try {
        const response = await Get(ApiRoutes.subscriptionListing);
        const token = getAccessToken()
        if(token){
            navigate("/ipass", { state: response?.data })

        }else{
            navigate("/", { state: response?.data })
        }

        return {
            status: response?.status,
            data: response?.data
        }
    } catch (error) {
        return {
            status: false,
            message: error.message
        }
    }
} 