import React, { createContext, useState } from 'react'
import { ConfigProvider } from 'antd';
import colors from '../theme/colors/colors';

export const AuthContext = createContext({});




const AuthProvider = ({ children }) => {


  const [data, setData] = useState("Hello World")

  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: colors.primary,
      },
    }}
  >
    <AuthContext.Provider value={{ data, setData }}>
      {children}
    </AuthContext.Provider>
    </ConfigProvider>
  )
}

export default AuthProvider
