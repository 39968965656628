import React, { useRef, useState, useContext, useEffect } from 'react'
import iPassLogo from '../../assets/images/svg/iPassLogo.svg'
import './style.scss'
import { BackIcon, CallIcon, DetailsIcon, MenuIcon } from '../../assets/icons/Icons'
import { OutlinedBtn, SearchInput } from '..'
import { useNavigate, useLocation } from 'react-router-dom'
import { Select,  } from 'antd';
import { LanguageContext } from '../context/languageContext'
import useSelectedLng from '../../functions/seletedLng'

function Index({ type, withLogo, withInput, tags, tagsList, selectedCategoryId, changeCategoryFilter,placeholder }) {
    
    const currentLng = useSelectedLng()
    const navigate = useNavigate();
    const location = useLocation();
    const scrollRef = useRef(null);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const { language, toggleLanguage } = useContext(LanguageContext);


    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const lngParams =  urlParams.get('lng');
        if(lngParams && lngParams === "en" || lngParams === "ar"){
            console.log("99999")
            toggleLanguage(lngParams)
          }
    },[])

    const handleMouseDown = (e) => {
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!startX) return;
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = x - startX;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setStartX(null);
    };

    const handleBackClick = () => {
        navigate(-1)
    };

    const langToggle = (v) => {
        toggleLanguage(v)
    }

    const scrollToElement = (index) => {
        if (scrollRef.current) {
            const element = scrollRef?.current?.children?.[index];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
            }
        }
    };

    return (
        <div className={`header__main__container ${type === "detail_call" ? "no__bg" : ""}`}>
            <div className='header__section'>
                
                {withLogo ? <div className='logo__box'>
                    <img src={iPassLogo} />
                </div>
                    : ""}

                  {/* {location.pathname === "/ipass" && <div className='flex items-center'>
                    <Select
                    style={{width: "100px"}}
                    onChange={(v)=>{langToggle(v)}}
                        value={language}
                        options={[
                        {
                            value: 'en',
                            label: 'Eng',
                        },
                        {
                            value: 'ar',
                            label: 'Arabic',
                        }
                        ]}toggleLanguage
                    />
                    </div>} */}
                {/* {type === "menu" ?
                    <div className='sub__section'>
                        <button className='p-0'>
                            <MenuIcon />
                        </button>
                        <SearchInput />
                    </div>
                    : ""} */}
                {type === "detail_call" ?
                    <div className='detail__Call__icon__menu'>
                        <div>
                            <button onClick={handleBackClick} >
                                <BackIcon />
                            </button>
                        </div>
                        {/* <div className='icon_section'>
                            <div>
                                <button>
                                    <DetailsIcon />
                                </button>
                            </div>
                            <div>
                                <button>
                                    <CallIcon />
                                </button>
                            </div>
                        </div> */}
                    </div>
                    : ""}
                {type === "back" ? <div className={currentLng === "en" ? 'sub__section': 'sub_section_arab'}>
                    <button onClick={handleBackClick} className='p-0'>
                        <BackIcon />
                    </button>
                    {withInput ? <SearchInput placeholder={placeholder} /> : ""}
                    {tags ? <div
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        className='w-full tag__container'>
                        {tagsList?.map((v, i) => {
                            return <OutlinedBtn scrollToElement={scrollToElement} index={i} classes="mr-4" title={v} selectedCategoryId={selectedCategoryId} onClick={changeCategoryFilter} />
                        })}
                    </div> : ""}
                </div> : ""}


            </div>
        </div>
    )
}

export default Index
