import { Decoding } from "./base64";


export const getAccessToken = () => {
  if(localStorage.getItem("access_token")){
    let token = Decoding(localStorage.getItem("access_token"));
    return token
  }
  return null
   
  };
  


  export const getUserData = () => {
    if(localStorage.getItem("userData")){
      let data = JSON.parse(Decoding(localStorage.getItem("userData")));
      return data
    }
    return null
     
    };
    

    export const getRefreshToken = () => {
      if(localStorage.getItem("refresh_token")){
        let refreshToken = Decoding(localStorage.getItem("refresh_token"));
        return refreshToken
      }
      return null
    }


    export const userIsLoggedIn = () => {
      if(getAccessToken()){
        return true
      }else {
        return false
      }
    }


    export const getMyPassData = () => {
      if(localStorage.getItem("mypassData")){
        let passData = Decoding(localStorage.getItem("mypassData"));
        return passData
      }
      return null
    }


