import React from 'react';
import './modal.scss';
import { NormalBtn } from '../..';

function Modal({ isOpen, onClose }) {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2 className='modal__heading p-2'>Congratulations</h2>
            <p className='text-center p-2  modal__text'>You Have succesfully purchase Fitness Pass</p>
            <div className='btn_modal_close'>
              <NormalBtn label="EXPLORE" onClick={onClose} />

            </div>
            <div className="link-container">
              <a href='' className='link_modal'>Maybe Later</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
