import axios from "axios";
import config from "../../config";
import { getAccessToken, getRefreshToken } from "../../functions/common";
import ApiRoutes from "../apiRoutes/routes";
import { Encoding } from "../../functions/base64";

const baseURL = config.baseURL;
const baseURLUm = config.baseURLUM

const service = axios.create({
  baseURL
});

service.interceptors.request.use((req) => {
  let token = getAccessToken();
  if (token) {
    let headers = { ...req.headers };
    req.headers = {
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  } else {
    let headers = { ...req.headers };
    req.headers = {
      ...headers,
    };
  }
  return req;
});

service.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status;
    if (status === 401) {
      originalRequest._retry = true;
      try {
        // Call the refreshToken API to get a new token
        const refreshTokenResponse = await axios.post(baseURLUm + ApiRoutes.refreshToken, {
          refresh_token: getRefreshToken()
        });

        const newToken = refreshTokenResponse?.data?.data?.access_token;

        // Store the new token
        localStorage.setItem('access_token', Encoding(newToken));

        // Set the Authorization header with the new token
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

        let queryOperator = originalRequest.url.includes("?") ? "&" : "?";
        let oldRequest =
          originalRequest.url.split("VPUAuthorization")[0] +
          `${queryOperator}VPUAuthorization=` +
          newToken;
        originalRequest.url = oldRequest;

        // Retry the original request with the new token
        return service(originalRequest);
      } catch (refreshError) {
        // window.location.href = "/relogin"
        return Promise.reject(refreshError);
      }

    } else if (status === 502 || status === 503) {
        window.location.href = "/underMaintenance"
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
