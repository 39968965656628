import React from 'react'
import Image from '../../assets/images/bottomlogo.png'
import './bottomlogo.scss'
import { useNavigate } from 'react-router-dom';
import { PassDetailIcon } from '../../assets/icons/Icons';

function Bottomlogo() {
const navigate = useNavigate();

  return (
    <div className="bottom__position" onClick={() => navigate("/passCard")}>
    <img src={Image} alt="Logo" className="clickable-logo"/>
    {/* <PassDetailIcon /> */}
</div>
  )
}

export default Bottomlogo