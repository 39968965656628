import { Button } from "antd";
import Icon from "@ant-design/icons";
import { underMaintenanceIcon } from "./underMaintenanceIcon";

export default function UnderMaintenance(props) {
  return (
<div className="under-maintenance px-12 h-screen flex items-center justify-center">
  <div className="text-center flex flex-col items-center justify-center www">
    <Icon rev={""} component={underMaintenanceIcon} size={300} />
    <p className="black text-base mt-8">
      The app is under maintenance. Please check back later. Thank you.
    </p>
  </div>
</div>
  );
}
