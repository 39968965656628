import React, { useEffect, useState, useRef, useCallback } from 'react';
import './ipass.scss';
import { getMyPassData, getUserData } from '../../../functions/common';
import ApiRoutes from '../../../utils/apiRoutes/routes';
import { Encoding } from '../../../functions/base64';
import { Get } from '../../../utils/services/request';
import QRCode from "react-qr-code";
import CurrentDateTime from './currentDate';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

function Ipasscard(props) {
    const [data, setData] = useState([]);
    const [mypass, setMyPass] = useState();
    const [remainingTime, setRemainingTime] = useState(15 * 60); // Display countdown time in seconds
    const countdownRef = useRef(15 * 60); // Internal countdown state in seconds
    const fifteenMinutes = 15 * 60 * 1000;

    useEffect(() => {
        getCardDetails();
        checkLastApiCall();
    }, []);

    // Optimized countdown timer using useRef to prevent re-rendering every second
    useEffect(() => {
        const countdownInterval = setInterval(() => {
            countdownRef.current -= 1;

            if (countdownRef.current <= 0) {
                clearInterval(countdownInterval);
                setRemainingTime(0);
                getMyPassDetails(); // Fetch details when timer reaches 0
            } else if (countdownRef.current % 1 === 0) { // Only update display every second
                setRemainingTime(countdownRef.current);
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [remainingTime]);


    const getCardDetails = async () => {
        const user = getUserData();
        if (user) {
            setData(user);
        } else {
            try {
                const response = await Get(ApiRoutes.subscriptionListing);
                if (response.status) {
                    const res = response?.data?.data;
                    if (res?.user) {
                        const userData = res.user;
                        localStorage.setItem('userData', Encoding(JSON.stringify(userData)));
                        setData(userData);
                    }
                }
            } catch (error) {
                return {
                    status: false,
                    message: error.message
                };
            }
        }
    };

    const getMyPassDetails = async () => {
        try {
            const response = await Get(ApiRoutes.mypass);
            if (response.status) {
                const res = response?.data?.data;
                setMyPass(res);
                localStorage.setItem('mypassData', Encoding(JSON.stringify(res)));
                localStorage.setItem('lastApiCall', Date.now());
                countdownRef.current = 15 * 60; // Reset internal timer
                setRemainingTime(15 * 60); // Reset displayed time
            }
        } catch (error) {
            return {
                status: false,
                message: error.message
            };
        }
    };

    const checkLastApiCall = useCallback(() => {
        const lastApiCall = parseInt(localStorage.getItem('lastApiCall') || '0', 10);
        const currentTime = Date.now();

        if (currentTime - lastApiCall >= fifteenMinutes) {
            getMyPassDetails();
        } else {
            const passData = JSON.parse(getMyPassData());
            setMyPass(passData);

            const remainingTime = Math.floor((fifteenMinutes - (currentTime - lastApiCall)) / 1000);
            countdownRef.current = remainingTime; // Update internal countdown state
            setRemainingTime(remainingTime); // Set displayed countdown time
        }
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };
    return (
        <div>
            <div className={props?.mainCard ? 'box__sizing_card_main flex flex-col justify-between h-full' : "box__sizing_card flex flex-row justify-between h-full"}>

                {/* Left Section */}
                <div className='card_left_wrapper'>
                    <div className='date_time_section'>
                        {/* <CurrentDateTime /> */}
                        <p className='mt-2 font-bold flex justify-center'>Refreshing in {formatTime(remainingTime)}</p>
                    </div>
                    {!props.mainCard && <div className='ipass_code_section'>
                        <p className='id_text'>iPASS</p>
                        <div className='pin_wrapper'>
                            <div className={props.mainCard ? "mainCard_pin" : "card_pin"}>{ mypass?.pin }</div>
                        </div>
                    </div>}
                </div>

                {/* Right Section */}
                <div className={props.mainCard ? "qr_wrapper_main" : "qr_wrapper"}>
                    <div>
                        <QRCode
                            size={256}
                            style={props.mainCard ? { width: "100%" } : { height: "auto", maxWidth: "100%", width: "100%" }}
                            value={encodeURIComponent(mypass?.url)}
                            viewBox={`0 0 256 256`}
                            fgColor="#FFFFFF"
                            bgColor="transparent"
                        />
                        {/* <p className='mt-2 font-bold flex justify-center'>{formatTime(remainingTime)}</p> */}
                    </div>
                </div>
                {props.mainCard && <div className='ipass_code_section_main'>
                    <p className='id_text flex justify-center'>OTP</p>
                    <div className='pin_wrapper_main'>
                        <div className={props.mainCard ? "mainCard_pin" : "card_pin"}>{ mypass?.pin}</div>
                    </div>
                </div>}
            </div>


        </div>
    );
}

export default Ipasscard;
