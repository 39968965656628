import React, { useState } from 'react'
import './purchase.scss'
import { Header, NormalBtn, NormalInput, NormalModal, DateInput } from '../../components';
import { useNavigate } from 'react-router-dom';
import { FitnessIcon } from '../../assets/icons/Icons';


function Index() {

    const [date, setDate] = useState('');

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        navigate("/ipass")
    };
    return (
        <div className='purchase__main' >


            <div className='purchase__input__section'>
                <div className='harder__setting'>
                    <div>
                        <Header type='back' />
                    </div>
                    <div>
                        <p className='header_heding' >
                            Purchase
                        </p>
                    </div>
                </div>
                <div>
                    <p className='text_def'>
                        PRODUCT
                    </p>
                    <div className='fitness_btn'>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                            <div>
                                <FitnessIcon />
                            </div>
                            <div>
                                <p className=''> Fitness pass</p>
                            </div>                        </div>

                        <div>
                            <p className='fit_text'>QR 99</p>
                        </div>

                    </div>
                </div>
                {/* <div className='flex__setting'>
                    <div>
                        <h2>FItness Pass</h2>
                    </div>
                    <div>
                        <h2>QR 99</h2>
                    </div>
                </div> */}
                {/* <a href='' className='link__style'>
                    Add anotherPass
                </a> */}
                <h4 className='payment__heading'>
                    PAYMENT DETAILS
                </h4>

                <div>

                    <div className='my-3'>
                        <h5 className='inp_text'>Card Number</h5>

                        <NormalInput placeholder=" 0000 0000 0000 0000" />
                    </div>
                    <div className='my-3'>
                        <h5 className='inp_text'>Name On Card</h5>

                        <NormalInput placeholder=" Eg. Muhammad Ahmed" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 30 }}>
                        <div className='my-3'>
                            <h5 className='inp_text'>EXPIRE DATE</h5>

                            <DateInput
                                name="date"
                                placeholder="03/07/2024"
                                value={date}
                                onChange={handleDateChange}
                                showIcon={true}
                            />
                        </div>
                        <div className='my-3'>
                            <h5 className='inp_text'>CVV</h5>

                            <DateInput
                                name="date"

                                value={date}
                                onChange={handleDateChange}
                                showIcon={false}

                            />
                        </div>
                    </div>


                </div>


                <div className='en_flex_setting'>
                    <div>
                        <p className='total__heading'>
                            GRAND TOTAL
                        </p>
                    </div>
                    <div>
                        <p className='total__heading'>QR 99</p>

                    </div>
                </div>
                <div>
                    <NormalBtn onClick={openModal} label="Play now" />
                    <NormalModal isOpen={isOpen} onClose={closeModal} />
                </div>
            </div>

        </div>
    )
}

export default Index