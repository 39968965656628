export const underMaintenanceIcon = () => (
    <svg
      width="59"
      height="47"
      viewBox="0 0 59 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 47V16.1562C0 14.9812 0.4425 13.9531 1.3275 13.0719C2.2125 12.1906 3.245 11.75 4.425 11.75H14.75V4.40625C14.75 3.23125 15.1925 2.20312 16.0775 1.32187C16.9625 0.440625 17.995 0 19.175 0H39.825C41.005 0 42.0375 0.440625 42.9225 1.32187C43.8075 2.20312 44.25 3.23125 44.25 4.40625V11.75H54.575C55.755 11.75 56.7875 12.1906 57.6725 13.0719C58.5575 13.9531 59 14.9812 59 16.1562V47H0ZM16.225 31.5781V35.9844H11.8V31.5781H4.425V42.5938H54.575V31.5781H47.2V35.9844H42.775V31.5781H16.225ZM4.425 16.1562V27.1719H11.8V22.7656H16.225V27.1719H42.775V22.7656H47.2V27.1719H54.575V16.1562H4.425ZM19.175 11.75H39.825V4.40625H19.175V11.75Z"
        fill="#002828"
      />
    </svg>
  );
  