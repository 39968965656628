import React from 'react';
import './newmodal.scss';
import { NormalBtn } from '../../..';

function Newmodal({ isOpen, onClose, onClickBtn }) {
    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="signup__success__modal">
                        <h2 className='modal__heading p-2'>Congratulations</h2>
                        <p className='text-center p-2  modal__text'>
                        You've successfully signed up for iPass. To unlock benefits, make sure to purchase at least one pass. Explore our diverse range of passes now and begin your journey today!
                        </p>
                        <div className='btn_modal_close'>
                            <NormalBtn label="EXPLORE" onClick={onClickBtn} />
                        </div>
                        
                    </div>
                </div>
            )}
        </>
    );
}

export default Newmodal;
