import React from 'react'
import { Input } from 'antd';
import './inp.scss'
function Sminput({ onChange, placeholder, name, value, type, lable, style, id, onBlur, onFocus }) {
  return (
    <div>
      <Input onFocus={onFocus && onFocus} onBlur={onBlur && onBlur} id={id} className='inpStyle' name={name ?? ""} style={style} lable={lable} value={value} type={type} placeholder={placeholder} onChange={onChange} />
    </div>
  )
}

export default Sminput