import React from 'react'
import './style.scss'
import ipassLogo from '../../assets/images/svg/iPassLogo.svg'


function Index({classes}) {
    return (
        <div className={`${classes ?? ""} splash__main`}>
            <div>
                <img src={ipassLogo} alt='logo' />
                <p>
                    Where Benefits
                    <br />
                    Meet Convenience
                </p>
            </div>
        </div>
    )
}

export default Index
