const ApiRoutes = {
    login: "/api/v1/login",
    subscriptionListing: "/api/v1/home",
    categoryByPass: "/api/v1/home/pass",
    outletsByPass:"/api/v1/instore-outlets/category",
    refreshToken: "/api/v1/auth/token",
    outletDetails:"/api/v1/instore-outlets",
    benefitDetails:"/api/v1/instore-outlets/benefits",
    mypass: "/api/v1/home/mypass"
};
export default ApiRoutes;
