
const en = {
    my_passes: "My passes",
    ipass: "iPASS",
    change_outlet: "Change Outlet",
    benefit: "BENEFITS",
    amenities: "AMENITIES",
    details: "Details", 
    offer_is: "This offer is subjected to",
    redeem_now: "REDEEM NOW",
    present_id: "Show this to the staff to redeem your benefit.",
    web_version: "Web Version",
    welcome_ipass: "Welcome to iPass!",
    welcome_para: "Explore exclusive passes tailored for you. Sign up now to unlock exciting benefits.",
    and_more: "and more",


    // Rules of use


    rules_of_use: "Rules Of Use",
    defination: "Definations",
    ipass_text: "A membership program offering exclusive deals, discounts, and benefits.",
    ipass_digital: "iPASS Digital Membership Card",
    ipass_digital_text: "A virtual card granting access to iPASS offers and benefits.",
    merchant: "Merchant",
    merchant_text: "A business or service provider participating in the iPASS program.",
    participating: "Participating Outlet",
    participating_text: "Specific locations where iPASS offers can be redeemed.",
    offer: "Offer",
    offer_Text: "A special deal, discount, or benefit available exclusively to iPASS members.",
    general_terms: "General Terms",
    general_terms_text: "By using any iPASS offers or benefits ('Offers'), you agree to comply with these Rules of Use ('Rules') and any specific terms associated with individual Offers. Please read these Rules carefully before redeeming any Offer.",


    offer_validity: "Offer Validity",
    offer_validity_text1: "Offers are valid only for the time period specified on the Offer unless otherwise stated.",
    offer_validity_text2: "Offers are redeemable exclusively at the issuing merchant or venue.",
    offer_validity_text3: "Offers apply solely to the items, services, and locations specified.",
    offer_validity_text4: "Offers cannot be combined with other promotions, discounts, or loyalty programs unless explicitly stated.",
    offer_validity_text5: "Offers do not cover additional charges such as service fees or taxes.",
    offer_validity_text6: "Offers are valid seven days a week unless otherwise specified, with minimal exclusion dates.",
    offer_validity_text7: "Unauthorized reproduction, resale, or modification of Offers is strictly prohibited. Any Offer found to be resold or bartered will be considered void.",

    redeem_offer: "Redeeming Your Offers",
    redeem_offer_text1: "To redeem an Offer, present your iPASS digital membership card to the outlet staff before your bill is issued.",
    redeem_offer_text2: "The merchant will enter your iPASS membership number into their system to apply the Offer.",
    redeem_offer_text3: "For multiple redemptions, repeat this process.",
    redeem_offer_text4: "In the event of any dispute, the bill must be settled according to local laws, and relevant parties will be contacted to resolve the issue.",


    dinning_offers: "Dining Offers",
    dinning_offer_text1: "Offers are not applicable for delivery or takeaway services unless explicitly mentioned on the Offer.",
    dinning_offer_text1: "Discounts do not cover service charges.",
    dinning_offer_text1: "Advance booking may be required. ",

    amusement_parks:  "Amusement Parks, Attractions & Leisure Offers",
    amusement_parks_text1: "Terms and conditions may vary by activity. Please refer to the individual merchant for details on age restrictions, safety guidelines, and other conditions.",
    amusement_parks_text2: "Prior booking may be required for certain activities or offers.",


    beauty_spa: "Beauty & Spas, Health & Fitness, and Retail Services Offers",
    beauty_spa_text1: "Present your iPASS digital card and membership number at the time of booking, prior to service completion, or when requesting the bill.",
    beauty_spa_text2: "For health and fitness Offers, ensure the card is presented before beginning any class or activity.",
    beauty_spa_text3: "Prior booking may be required for specific services or activities.",


    special_offers: "Special Offers",
    special_offers_text1: "Special or complimentary Offers may have different validity periods and may cover items or services separate from the main Offers.",
    special_offers_text2: "The validity of Special Offers is marked on the Offer, but iPASS reserves the right to remove these Offers at any time. We are not liable if a Special Offer is discontinued before its expiration.",
    special_offers_text3: "Special Offers are also subject to these Terms of Use.",



    new_offers: "New Offers",
    new_offers_text1: "iPASS may introduce new Offers throughout the year from new merchants. These new Offers will be made available to all iPASS members, and the general Terms of Use shall apply.",
    


    exclusion_days: "Exclusion Days",
    exclusion_days_text1: " Offers can be redeemed seven days a week, except on the following exclusion days in Qatar, as well as any public holidays announced by the Qatari government:",
    exclusion_days_text1_1: "Christmas Eve",
    exclusion_days_text1_2: "Christmas Day",
    exclusion_days_text1_3: "New Year’s Eve",
    exclusion_days_text1_4: "New Year’s Day",
    exclusion_days_text1_5: "Eid Al Fitr",
    exclusion_days_text1_6: "Eid Al Adha",
    exclusion_days_text1_7: "Valentine’s Day",
    exclusion_days_text1_8: "Qatar National Day",
    exclusion_days_text1_9: "National Sports Day",
    exclusion_days_text2: "Please note that some outlets may be closed during Ramadan, summer, and other religious holidays. iPASS is not responsible for any outlet that may be temporarily or permanently closed during the Offer's validity period."

};

const arb = {
    my_passes: "بطاقاتي",
    ipass: "iPass",
    change_outlet: "تغييرالمتجر",
    benefit: "المزايا",
    amenities: "وسائلالراحة",
    details: "التفاصيل",
    offer_is: "يخضعهذاالعرضلقواعدالاستخدام",
    rules_of_use: "لقواعدالاستخدام",
    redeem_now: "استردالآن",
    present_id: "قدمبطاقتكإلىالموظفينلاستردادالمزايا",
    web_version: "إصدارالويب",
    welcome_ipass: "مرحبابكفي iPass!",
    welcome_para: "استكشفبطاقاتحصريةمصممةًوفقالاحتياجاتك.سجلالآنلتحصلعل",
    and_more: "وأكثر من ذلك",


   // Rules of use


    rules_of_use: "Rules Of Use",
    defination: "Definatio",
    ipass_text: "A membership program offering exclusive deals, discounts, and benefi.",
    ipass_digital: "iPASS Digital Membership Ca",
    ipass_digital_text: "A virtual card granting access to iPASS offers and benefi.",
    merchant: "Mercha:",
    merchant_text: "A business or service provider participating in the iPASS progr.",
    participating: "Participating Outl",
    participating_text: "Specific locations where iPASS offers can be rede.",
    offer: "Off",
    offer_Text: "A special deal, discount, or benefit available exclusively to iPASS membe.",
    general_terms: "General Ter",
    general_terms_text: "By using any iPASS offers or benefits ('Offers'), you agree to comply with these Rules of Use ('Rules') and any specific terms associated with individual Offers. Please read these Rules carefully before redeeming any Of.",


    offer_validity: "Offer Validi",
    offer_validity_text1: "Offers are valid only for the time period specified on the Offer unless otherwise sta.",
    offer_validity_text2: "Offers are redeemable exclusively at the issuing merchant or ve.",
    offer_validity_text3: "Offers apply solely to the items, services, and locations specif.",
    offer_validity_text4: "Offers cannot be combined with other promotions, discounts, or loyalty programs unless explicitly stat.",
    offer_validity_text5: "Offers do not cover additional charges such as service fees or ta.",
    offer_validity_text6: "Offers are valid seven days a week unless otherwise specified, with minimal exclusion dat.",
    offer_validity_text7: "Unauthorized reproduction, resale, or modification of Offers is strictly prohibited. Any Offer found to be resold or bartered will be considered vo.",

    redeem_offer: "Redeeming Your Offe",
    redeem_offer_text1: "To redeem an Offer, present your iPASS digital membership card to the outlet staff before your bill is issu.",
    redeem_offer_text2: "The merchant will enter your iPASS membership number into their system to apply the Off.",
    redeem_offer_text3: "For multiple redemptions, repeat this proc.",
    redeem_offer_text4: "In the event of any dispute, the bill must be settled according to local laws, and relevant parties will be contacted to resolve the iss.",


    dinning_offers: "Dining Offe",
    dinning_offer_text1: "Offers are not applicable for delivery or takeaway services unless explicitly mentioned on the Off.",
    dinning_offer_text2: "Discounts do not cover service char.",
    dinning_offer_text3: "Advance booking may be requir.",

    amusement_parks:  "Amusement Parks, Attractions & Leisure Offe",
    amusement_parks_text1: "Terms and conditions may vary by activity. Please refer to the individual merchant for details on age restrictions, safety guidelines, and other conditi.",
    amusement_parks_text2: "Prior booking may be required for certain activities or offe.",


    beauty_spa: "Beauty & Spas, Health & Fitness, and Retail Services Off",
    beauty_spa_text1: "Present your iPASS digital card and membership number at the time of booking, prior to service completion, or when requesting the bi.",
    beauty_spa_text2: "For health and fitness Offers, ensure the card is presented before beginning any class or activ.",
    beauty_spa_text3: "Prior booking may be required for specific services or activit.",


    special_offers: "Special Offe",
    special_offers_text1: "Special or complimentary Offers may have different validity periods and may cover items or services separate from the main Offe.",
    special_offers_text2: "The validity of Special Offers is marked on the Offer, but iPASS reserves the right to remove these Offers at any time. We are not liable if a Special Offer is discontinued before its expirati.",
    special_offers_text3: "Special Offers are also subject to these Terms of U.",



    new_offers: "New Offe",
    new_offers_text1: "iPASS may introduce new Offers throughout the year from new merchants. These new Offers will be made available to all iPASS members, and the general Terms of Use shall app.",
    


    exclusion_days: "Exclusion Days",
    exclusion_days_text1: " Offers can be redeemed seven days a week, except on the following exclusion days in Qatar, as well as any public holidays announced by the Qatari governm",
    exclusion_days_text1_1: "Christmas E",
    exclusion_days_text1_2: "Christmas Da",
    exclusion_days_text1_3: "New Year’s Ev",
    exclusion_days_text1_4: "New Year’s Da",
    exclusion_days_text1_5: "Eid Al Fit",
    exclusion_days_text1_6: "Eid Al Ad",
    exclusion_days_text1_7: "Valentine’s Da",
    exclusion_days_text1_8: "Qatar National Da",
    exclusion_days_text1_9: "National Sports Da",
    exclusion_days_text2: "Please note that some outlets may be closed during Ramadan, summer, and other religious holidays. iPASS is not responsible for any outlet that may be temporarily or permanently closed during the Offer's validity peri."
};

export default function translatedText(name) {
  if (name == "en") {
    return en;
  } else {
    return arb;
  }
}








