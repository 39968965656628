import ApiRoutes from "../../../apiRoutes/routes";
import { Get } from "../../request"

export const fetchOutletsByCategory = async (categoryId) => {
    try {
            const response = await Get(`${ApiRoutes.outletsByPass}/${categoryId}`);
            return response?.data ?? {}
     
    } catch (error) {
        return {
            status: false,
            data: {},
            error: error?.message
        }
    }
}

export const fetchOutletDetailsById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.outletDetails}/${id}`);
        return response?.data ?? {}
    } catch (error) {
        return {
            status: false,
            data: {},
            error: error?.message
        }
    }
}

export const fetchBenefitDetailsById = async (id) => {
    try {
        const response = await Get(`${ApiRoutes.benefitDetails}/${id}`);
        return response?.data ?? {}
    } catch (error) {
        return {
            status: false,
            data: {},
            error: error?.message
        }
    }
}