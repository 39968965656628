import React from 'react';
import './DateInput.scss';
import { Input } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

export default function DateInput({ name, placeholder, onChange, value, showIcon = true }) {
  return (
    <div className="date-input-container">
      <Input
        onChange={onChange}
        name={name}
       
        className='inp__style'
        placeholder={placeholder}
        suffix={showIcon ? <CalendarOutlined className="date-icon" /> : null}
      />
    </div>
  );
}
