import React, { useEffect, useState } from 'react'
import './alwab.scss'
import { Header, RoundedButton, Bottomlogo } from '../../components'
import { DwonArrowIcon, NextArrowIcon } from '../../assets/icons/Icons'
import { fetchOutletDetailsById } from '../../utils/services/apiFunctions/outlets'
import { useNavigate, useParams } from 'react-router-dom'
import OutletModal from '../../components/modals/normalModal/bottomrediomodal'
import { Spin } from 'antd'
import { getUserData } from '../../functions/common'
import useSelectedLng from '../../functions/seletedLng'
import translatedText from '../../globalization/language'

function AlWab() {
    const currentLng = useSelectedLng()
    const params = useParams();
    const navigate = useNavigate();
    const userData= getUserData()
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openOutletModal, setOpenOutletModal] = useState(false);

    const getOutletDetails = async (id) => {
        setIsLoading(true)
        const response = await fetchOutletDetailsById(id ?? params?.id ?? "")
        setData(response?.data ?? {})
        setIsLoading(false)
    }

    useEffect(() => {
        getOutletDetails()
    }, [])

    const goToBenefitDetails = (id) => {
        navigate(`/benefits/${id}`, { state: { passName: data?.name } })
    }

    useEffect(()=>{
        const root = document.getElementById('root')
        if(openOutletModal){
            root.style.overflow = 'hidden'
            root.style.height = '100vh'
        }else{
            root.style.overflow = 'auto'
            root.style.height = 'auto'
        }
    },[openOutletModal])

    return (
        <>
            {
                isLoading ? <div className="flex justify-center items-center w-full h-screen">
                    <Spin size='medium' />
                </div> :    
                    <div>
                        <div style={{ backgroundImage: `url(${data?.medias?.[0]?.path})` }} className='wab__bg_img'>
                            <div>
                                <Header type='detail_call' />
                            </div>
                        </div>
                        <div className='content-center'>
                            <div className='text_box'>
                                <h4 className='heidng_text'>
                                    {data?.name}
                                </h4>
                                <button onClick={() => setOpenOutletModal(true)} className=' pera__text_icon'>
                                {translatedText(currentLng).change_outlet} <DwonArrowIcon />
                                </button>
                            </div>
                            <div>
                                <p className='text'>{translatedText(currentLng).benefit}</p>
                                {data?.benefits?.map((e, i) => (
                                    <button onClick={() => goToBenefitDetails(e?.external_id)} className='btn_style text-left' key={i}> {/* Ensure to provide a unique key */}
                                        <div>
                                            <p className='text__style'>
                                                {e.name}
                                            </p>
                                            <p className='availbale_voucher'>Available Vouchers {e?.user_benefit?.vouchers_remaining}</p>
                                            <p>Total Vouchers {e?.user_benefit?.total_vouchers}</p>
                                        </div>
                                        <div>
                                            <NextArrowIcon />
                                        </div>
                                    </button>
                                ))}

                                {/* <div className='box__styling'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                        <div>
                                            <p className='text__setting'>About</p>

                                        </div>
                                        <div>
                                            <DwonArrowIcon />
                                        </div>
                                    </div>

                                    <p className='text__styling'>
                                        MTM Group in Qatar Offers specialized men,gyms like the men and aspires Active  These gyms provide persnol training swimming and modren facilities for  ftness enhesit
                                    </p>

                                    <p style={{ opacity: '0.8', paddingTop: '20px' }}>Timings:</p>
                                    <p style={{ opacity: '0.8' }}>{data?.operating_hours}</p>

                                    <p style={{ opacity: '0.8', paddingTop: '20px' }}>Location:</p>
                                    <a style={{ textDecoration: 'underline' }}>MTM Group-GoogleMaps</a>
                                </div> */}
                                <div>
                                    {data?.amenities?.length && <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                        <div>
                                            <p className='text__setting'>{translatedText(currentLng).amenities}</p>

                                        </div>
                                        {/* <div>
                                            <DwonArrowIcon />
                                        </div> */}
                                    </div>}
                                    {/* <p className='text__styling' style={{ paddingTop: '10px' }}>
                                        MTM Group in Qatar Offers specialized men,gyms like the men and aspires Active  These gyms provide persnol training swimming and modren facilities for  ftness enhesit
                                    </p> */}
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap-reverse'}}>
                                        {
                                            data?.amenities?.map((e, i) => (
                                                <div key={i}>
                                                    <RoundedButton label={e.name} />
                                                </div>
                                            ))
                                        }
                                    </div>

                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                        <div>
                                            <p className='text__setting' style={{ paddingTop: '15px', paddingBottom: "50px" }}>Rules of Use</p>

                                        </div>
                                        <div style={{ paddingTop: '15px', paddingBottom: "50px" }}>
                                            <DwonArrowIcon />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                      {userData && <div className='postion_box'>
                            <button className={currentLng === "en" ? 'bottom_logo' : 'bottom_logo_arb'}>
                                <Bottomlogo />
                            </button>
                        </div>}
                        <OutletModal data={data} getOutletDetails={getOutletDetails} onClose={() => setOpenOutletModal(false)} outlets={data?.merchant?.outlets} isOpen={openOutletModal} />
                    </div>
            }
        </>
    )
}

export default AlWab