import React from 'react'
import './button.scss'
function SmButton({ label, onClick, classes, disabled, onlyOutline, fixedBtn }) {
  return (
    <button disabled={disabled} className={`button_style ${classes ?? ""} ${onlyOutline ? "only__outline":""} ${fixedBtn ? "fixed__btn":""}`} onClick={onClick}>
      {label}
    </button>
  )
}

export default SmButton