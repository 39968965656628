import React from "react";
import './style.scss'
import { useNavigate } from 'react-router-dom'

const Index = ({ name, background, categoryList, id }) => {
    const navigate = useNavigate();
    return <button onClick={() => {
        navigate('/pass', { state: { categoryList,categoryId:id } });
    }} className="category__card__main">
        {background ? <img src={background} /> : ""}
        <div className="text__section">
            <h2>
                {name}
            </h2>
        </div>
    </button>
}

export default Index;