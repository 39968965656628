import React, { useEffect } from 'react'
import './style.scss'

function Index({ title, classes, onClick, selectedCategoryId, scrollToElement, index }) {
    const isActive = selectedCategoryId === title?.id;

    useEffect(()=>{
        isActive && scrollToElement(index)
    },[selectedCategoryId])

    return (
        <button onClick={() => onClick(title?.id)} type='secondary' className={`outlined__btn__main ${isActive ? "active__btn" : ""} ${classes ?? ""}`}>
            {title?.name}
        </button>
    )
}

export default Index
