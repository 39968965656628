import { Col } from 'antd'
import React from 'react'
import './style.scss'
import { breakdowns } from '../../utils/constants'

function Index({ children }) {
    return (
        <Col
            {...breakdowns}
            className=''
            style={{}}
        >{children}
        </Col>
    )
}

export default Index