import React from 'react'
import './fitnes.scss'
import { Ipassicon, NextIcon } from '../../../assets/icons/Icons'
export default function FItnessCard({data}) {
    const passes = data?.passes[0]
    return (
        <div className='pb-4'>
            <div className='box__sizing'>
                <p className='text_styling'>{passes?.name}</p>
                <div className='card_body'>
                    <div>
                        <p className='icon_text'>
                            <Ipassicon />
                        </p>
                    </div>
                    <div>
                        <NextIcon />
                    </div>
                </div>
            </div>

        </div>
    )
}
