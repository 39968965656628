import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { routes } from './routesListing'

const AppRouter = () => {

    const PrivateRoute = ({ children }) => {
        const token = localStorage.getItem('token')
        return token ? children : <Navigate to="/login" />
    }

    return (
        <BrowserRouter>
            <Routes>
                {routes?.map((v, i) => {
                    return <Route key={i} path={v?.path} element={v?.private ? <PrivateRoute children={v?.element} /> : v?.element} />
                })}
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
