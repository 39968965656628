import React from 'react';
import './BottomModal.scss';
import { Col } from 'antd';
import { breakdowns } from '../../../../utils/constants';
import { CloseOutlinedIcon } from '../../../../assets/icons/Icons';
import useSelectedLng from '../../../../functions/seletedLng';
import translatedText from '../../../../globalization/language';

const BottomModal = ({ isOpen, onClose }) => {
     const currentLng = useSelectedLng()
    return (
        <div className={`bottom__modal__rule ${isOpen ? 'open' : ''}`}>
            <Col {...breakdowns}>
            <div className="modal-content">
                <button className={currentLng === "en" ? "close-button" : "close-buttonArab"}onClick={onClose}>
                    <CloseOutlinedIcon />
                </button>
                <h4 className='modal__text'>{translatedText(currentLng).rules_of_use}</h4>
                <h4 className='modal__text_defination'>{translatedText(currentLng).defination}</h4>

                <div><strong>{translatedText(currentLng).ipass}:</strong> <span>{translatedText(currentLng).ipass_text}</span></div>
                <div><strong>{translatedText(currentLng).ipass_digital}:</strong> <span>{translatedText(currentLng).ipass_digital_text}</span></div>
                <div><strong>{translatedText(currentLng).merchant}</strong> <span>{translatedText(currentLng).merchant_text}</span></div>
                <div><strong>{translatedText(currentLng).participating}</strong> <span>{translatedText(currentLng).participating_text}</span></div>
                <div><strong>{translatedText(currentLng).offer}</strong> <span>{translatedText(currentLng).offer_Text}</span></div>

                <br />
                <h4 className='modal__text'>{translatedText(currentLng).general_terms}</h4>
                <p>{translatedText(currentLng).general_terms_text}</p>

                <br />
                <h4 className='modal__text'>{translatedText(currentLng).offer_validity}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).offer_validity_text1}
                    </li>
                    <li>
                    {translatedText(currentLng).offer_validity_text2}
                    </li>
                    <li>
                    {translatedText(currentLng).offer_validity_text3}
                    </li>
                    <li>
                    {translatedText(currentLng).offer_validity_text4}
                    </li>
                    <li>
                    {translatedText(currentLng).offer_validity_text5}
                    </li>
                    <li>
                    {translatedText(currentLng).offer_validity_text6}
                    </li>
                    <li>
                    {translatedText(currentLng).offer_validity_text7}
                    </li>

                </ul>

                <br />
                <h4 className='modal__text'>{translatedText(currentLng).redeem_offer}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).redeem_offer_text1}
                    </li>
                    <li>
                    {translatedText(currentLng).redeem_offer_text2}
                    </li>
                    <li>
                    {translatedText(currentLng).redeem_offer_text3}  
                    </li>
                    <li>
                    {translatedText(currentLng).redeem_offer_text4}                
                    </li>
                </ul>


                <br />
                <h4 className='modal__text'> {translatedText(currentLng).dinning_offers}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).dinning_offer_text1}
                    </li>
                    <li>
                    {translatedText(currentLng).dinning_offer_text2}
                    </li>
                    <li>
                    {translatedText(currentLng).dinning_offer_text3}      
                    </li>
                </ul>



                <br />
                <h4 className='modal__text'> {translatedText(currentLng).amusement_parks}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).amusement_parks_text1}
                    </li>
                    <li>
                    {translatedText(currentLng).amusement_parks_text2}
                    </li>
                </ul>

                <br />
                <h4 className='modal__text'>{translatedText(currentLng).beauty_spa}</h4>

                <ul className='list__style'>
                    <li>
                         {translatedText(currentLng).beauty_spa_text1}
                    </li>
                    <li>
                    {translatedText(currentLng).beauty_spa_text2}
                    </li>
                    <li>
                    {translatedText(currentLng).beauty_spa_text3}
                    </li>
                </ul>

                <br />
                <h4 className='modal__text'>{translatedText(currentLng).special_offers}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).special_offers_text1}
                    </li>
                    <li>
                    {translatedText(currentLng).special_offers_text2}
                    </li>
                    <li>
                    {translatedText(currentLng).special_offers_text3}
                    </li>
                </ul>

                <br />
                <h4 className='modal__text'>{translatedText(currentLng).new_offers}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).new_offers_text1}
                    </li>
                </ul>


                <br />
                <h4 className='modal__text'>{translatedText(currentLng).exclusion_days}</h4>

                <ul className='list__style'>
                    <li>
                    {translatedText(currentLng).exclusion_days_text1}
                    </li>
                         <ul className='ml-12'>
                            <li>{translatedText(currentLng).exclusion_days_text1_1}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_2}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_3}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_4}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_5}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_6}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_7}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_8}</li>
                            <li>{translatedText(currentLng).exclusion_days_text1_9}</li>
                        </ul>
                    <li>
                         {translatedText(currentLng).exclusion_days_text2}
                    </li>
                </ul>

            </div>
            </Col>
        </div>
    );
};

export default BottomModal;
