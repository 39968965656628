import React, { useState } from 'react'
import { NormalInput } from '../..'
import './style.scss'
import { DatePicker, Radio } from 'antd';

const Index = ({ onChange, value, placeholder, name, label, type }) => {

    const [inputActive, setInputActive] = useState(false);

    const onFocus = () => {
        setInputActive(true)
    }
    const onBlur = () => {
        setInputActive(false)
    }

    return (
        <div>
            <p className={`${inputActive ? "active__label" : ""} input__label`}>
                {label}
            </p>
            {type === "date" ?
                <DatePicker format='DD/MM/YYYY' onFocus={onFocus} onBlur={onBlur} className='date-picker' onChange={onChange} /> :
                type === "radio" ? <Radio.Group>
                    <div className='radio__main'>
                        <div className='radio__section'>
                            <Radio value="male">Male</Radio>
                        </div>
                        <div className='radio__female__section'>
                            <Radio value="female">Female</Radio>
                        </div>
                    </div>
                </Radio.Group> :
                    <NormalInput onFocus={onFocus} onBlur={onBlur} onChange={onChange} name={name} value={value} placeholder={placeholder} />
            }
        </div>
    )
}

export default Index