import React, { useEffect, useState } from 'react'
import { PassCard } from '../../components'
import passCard from '../../assets/images/passes/passCard1.png'
import { fetchAllPasses } from '../../utils/services/apiFunctions/passes'
import {Spin} from "antd";
import { useNavigate } from 'react-router-dom';
import { Encoding } from '../../functions/base64';

function Index({ rootClasses }) {
    const navigate = useNavigate();
    const [passes, setPasses] = useState([])
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getAllPasses()
    }, [])

    const getAllPasses = async () => {
        setLoading(true)
        try {
            const response = await fetchAllPasses(navigate);
            if (response.status) {
                const res = response?.data?.data
                setLoading(false)
                setPasses(res?.data || res || [])
                if(res?.user){
                    const userData = res.user
                    localStorage.setItem('userData', Encoding(JSON.stringify(userData)));
                }
            }
            return response?.data;
        } catch (error) {
            setLoading(false)
            return {
                status: false,  
                message: error.message
            }
        }
   
    }
    const passBySubscription = (externalId) => {
        navigate('/pass-details', { state: { externalId } });
    }
    return (
        <div className={`flex flex-wrap justify-between gap-y-3 mt-6 ${rootClasses}`}>
            {loading ? (
                <div className="flex justify-center items-center w-full h-screen">
                    <Spin size='medium' />
                </div>
            ) : (
                passes?.map((data, index) => {
                    return <PassCard onClick={()=> passBySubscription(data?.passes?.[0]?.external_id)} key={index} data={data} />;
                })
            )}
        </div>
    )
}

export default Index
