import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'antd';
import { Header, MainPageHeading, Newmodal, NormalBtn, FormInputWithLabel } from '../../components'
import './signup.scss'

function Index() {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        date: ""
    })

    const onChange = (e) => {
        const { value, name } = e?.target ?? {};
        setData(data => ({ ...data, [name]: value }))
    }

    const goToHome = () => {
        navigate("/")
    }

    const openModal = () => {
        setIsModalOpen(true)
    }



    return (
        <Form className="content-center">
            <Header type={"back"} />
            <div className='signup__main'>
                <div className='signup__inp__setting'>
                    <MainPageHeading text={"Sign Up"} />
                    <div className='signup_section'>
                        <FormInputWithLabel value={data?.firstName} onChange={onChange} label={"FIRST NAME"} placeholder="eg. Ahmed" name="firstName" />
                        <FormInputWithLabel value={data?.lastName} onChange={onChange} label={"Last Name"} placeholder="eg. Mohammed" name="lastName" />
                        <FormInputWithLabel value={data?.email} onChange={onChange} label={"Email"} placeholder="eg. ahmed@domainname.com" name="email" />
                        <FormInputWithLabel type="date" value={data?.date} label={"DATE OF BIRTH"} placeholder="DATE OF BIRTH" name="date" />
                        <FormInputWithLabel type="radio" value={data?.date} label={"Gender"} placeholder="DATE OF BIRTH" name="gender" />
                        <p className='terms__text' >
                            By signing up, I accept the
                            <a> Terms & Condition</a>
                        </p>
                        <NormalBtn onClick={openModal} label='SIGN UP' />
                    </div>
                </div>
            </div>
            <Newmodal onClickBtn={goToHome} isOpen={isModalOpen} />
        </Form>
    )
}


export default Index