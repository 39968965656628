import React from 'react';
import './onbording.scss'
import { OnboardingCarousel } from '../../components';

const Index = (props) => {
    return (
        <div className='w-1/2'>
            <OnboardingCarousel {...props} />
        </div>
    );
}

export default Index;
