import React from 'react'
import {Spin} from "antd"

function Loader(clas) {
  return (
<div style={{height: "75vh"}} className={`flex justify-center items-center w-full ${clas}`}>
        <Spin size='medium' />
      </div>
  )
}

export default Loader;
