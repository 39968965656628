import React from 'react'
import './heading.scss'

const Index = ({text}) => {
  return (
    <h1 className='heading__main'>
        {text}
    </h1>
  )
}

export default Index