import React, { useState } from 'react';
import './RadioModal.scss';
import { Col } from 'antd';
import { breakdowns } from '../../../../utils/constants';
import { CloseOutlinedIcon } from '../../../../assets/icons/Icons';
import { useNavigate } from 'react-router-dom';

const OutletModal = ({ isOpen, onClose, outlets, getOutletDetails, data }) => {

    const navigate = useNavigate();
    const [selectedRadio, setSelectedRadio] = useState(null);


    const handleRadioChange = (index) => {
        setSelectedRadio(index);
        if(outlets?.[index]?.external_id){
            const id = outlets?.[index]?.external_id;
            navigate(`/details/${id}`);
            getOutletDetails(id)
        }
        onClose()
    };

    return (
        <div className={`bottom_radio_modal ${isOpen ? 'open' : ''}`}>
            <Col {...breakdowns} style={{ padding: 0 }}>
                <div className="modal-content">
                    <div className='flex__setting'>
                        <div>
                            <p className='text_style'>Select an Outlet</p>
                        </div>
                        <div>
                            <button onClick={onClose}>
                                <CloseOutlinedIcon />
                            </button>
                        </div>
                    </div>

                    <div className='my-5'>
                        {outlets?.map((e, i) => (
                            <button
                                key={i}
                                onClick={
                                    () => handleRadioChange(i)
                                }
                                className={`border_setting ${selectedRadio === i ? 'selected' : ''}`}
                            >
                                <div>
                                    <p className='card_text'>{e.name}</p>
                                </div>
                                <div className='ring__container'>
                                    {e?.name === data?.name && <div />}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </Col>
        </div>
    );
};

export default OutletModal;
