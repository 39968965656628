export function Encoding(string) {
  if(!string){
    return null
  }
    const codeUnits = new Uint16Array(string?.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return window.btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

export function Decoding(encoded) {
  const binary = window.atob(encoded);
  const bytes = new Uint8Array(binary.length);
  
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  
  // Check if the length of bytes is even
  if (bytes.length % 2 !== 0) {
    // If not, add an extra zero byte
    const evenBytes = new Uint8Array(bytes.length + 1);
    evenBytes.set(bytes);
    return String.fromCharCode(...new Uint16Array(evenBytes.buffer));
  }

  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}