import ApiRoutes from "../../../apiRoutes/routes";
import { Get } from "../../request"

export const fetchCatgoriesByPassId = async (passId) => {
    try {
        const response = await Get(`${ApiRoutes.categoryByPass}/${passId}/categories`);
        return response?.data;
    } catch (error) {
        return {
            status: false,
            message: error.message
        }
    }
}