import React, { useEffect, useState } from 'react'
import { Alert } from 'antd';
import { CategoryCard } from '../../components'
import { fetchCatgoriesByPassId } from '../../utils/services/apiFunctions/categories'
import Loader from '../../components/loader/loader'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'


function Index({setPassName}) {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const externalPassId = queryParams.get('passId');
    const { externalId } =  location.state  || {};
    const extenalIdByRedirct = location?.state?.data?.data[0]?.external_id

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(false);


    const getCategories = async() => {
            setLoading(true)
            const response  =await fetchCatgoriesByPassId(externalId || externalPassId || extenalIdByRedirct);
            if(response.status){
                setLoading(false)
                setCategories(response?.data?.categories ?? [])
                setPassName(response?.data?.pass ?? []);
                setErr(false)
            }else {
                setErr(true)
                setTimeout(()=>{
                    navigate("/404")
                },2000)
            }
        
    }

    useEffect(()=>{
        getCategories()
    },[])

    return (
        <>
       { !!err && <Alert message="Something went wrong" type="error" />}
       {loading ? <Loader /> : <div className='flex flex-wrap justify-between gap-y-3'>
            {categories.map((category, index) => {
                return <CategoryCard categoryList={categories} id={category?.id} key={index} name={category.name} background={category?.medias?.[0]?.path}  />
            })}
        </div>}
        </>
    )
}

export default Index
