
import PageNotFound from "../components/404/404";
import Relogin from "../components/Relogin/relogin";
import UnderMaintenance from "../components/underMaintenance/underMaintenance";
import Details from "../pages/Details";
import AlWab from "../pages/alwabgym";
import PassCard from "../pages/passCard";
import { Home, Onboarding, Purchase, SignUp, VerifyOtp, Fitness, UserSignUp, Category, Pass, } from "./../pages";


export const routes = [
    {
        path: "/",
        element: <Home />,
        private: false
    },

    {
        path: '/purchase',
        element: <Purchase />,
        private: false
    },
    {
        path: '/edit-profile',
        element: <SignUp />,
        private: false
    },
    {
        path: '/onbording',
        element: <Onboarding />,
        private: false
    },

    {
        path: '/signup',
        element: <SignUp />,
        private: false
    },
    {
        path: '/verify-otp',
        element: <VerifyOtp />,
        private: false
    },
    {
        path: '/ipass',
        element: <Fitness />,
        private: false
    },
    {
        path: '/pass-details',
        element: <Category />,
        private: false
    },
    {
        path: '/pass',
        element: <Pass />,
        private: false
    },

    {
        path: '/verification',
        element: <UserSignUp />,
        private: false
    },
    {
        path: '/details/:id',
        element: <AlWab />,
        private: false
    },
    {
        path: '/benefits/:id',
        element: <Details />,
        private: false
    },
    {
        path: '/passCard',
        element: <PassCard />,
        private: false
    },
    {
        path: "/underMaintenance",
        element: <UnderMaintenance />,
        private: false
    },
    {
        path: "/relogin",
        element: <Relogin />,
        private: false
    },
    {
        path: "*",
        element: <PageNotFound />,
        private: false
    }

]